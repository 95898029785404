import { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Color } from '../Theme';
import { useScopedState } from '../../lib/state';
import { searchState } from '../../context/searchStore';

export const OmnibarSearchButtonStyle = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
`;

const Styled = {
  Button: styled.button<{ loading: 'true' | 'false' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: calc(100% - 0.6rem);
    height: calc(100% - 0.6rem);
    font-size: 1.06rem;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    background: none;
    background-color: ${Color.primary};
    border-radius: 4px;
    border: none;
    outline: none;
    color: ${Color.white};

    cursor: pointer;

    > .MuiCircularProgress-root {
      position: absolute;
      opacity: ${({ loading }) => (loading === 'true' ? 1 : 0)};
    }

    > svg,
    > .MuiCircularProgress-root {
      transition: opacity 200ms;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid transparent;
      border-radius: inherit;

      box-sizing: border-box;
    }

    &:hover {
      background-color: ${darken(0.1, Color.primary)};

      &::after {
        border-color: ${Color.secondary};
      }
    }

    &:focus,
    &:active {
      background-color: ${darken(0.1, Color.primary)};

      &::after {
        border-color: ${Color.primary};
      }
    }

    > span.spacer {
      flex: 1;
    }

    > svg {
      margin-left: 0.2rem;
      color: ${Color.white};
      opacity: ${({ loading }) => (loading === 'true' ? 0 : 1)};

      &:first-child {
        margin-left: 0rem;
      }
    }

    .badge {
      margin-right: 0;
      margin-left: 0.5rem;
      font-weight: normal;
    }
  `,
};

export const OmnibarSearchButton: FC<{
  icon?: JSX.Element;
  count?: number;
  iconRight?: JSX.Element;
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}> = ({ icon, count = 0, iconRight, className = '', onClick, children }) => {
  const { isSearchLoading } = useScopedState(searchState, ['isSearchLoading']);

  return (
    <OmnibarSearchButtonStyle>
      <Styled.Button loading={isSearchLoading ? 'true' : 'false'} className={className} onClick={onClick}>
        <CircularProgress aria-hidden="true" size={20} thickness={4.5} style={{ color: Color.white }} />
        {icon || null} {children}
        {/*iconRight !== undefined ? iconRight : <MenuDownIcon />*/}
      </Styled.Button>
    </OmnibarSearchButtonStyle>
  );
};
