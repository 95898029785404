import { CSSProperties, FC, memo, MouseEvent } from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Color } from '../Theme';
import { SelectBase, SelectProps, ItemComponentProps } from './SelectBase';
import { compareSelectItems, SelectItemDictionary, SelectOption } from '../../common-react/selectOptionLogic';

const Container = styled.div`
  width: 100%;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const CheckboxRow = styled.div`
  flex: 1;
  padding: 0.5rem 0 0 0;
  user-select: none;

  > div {
    display: flex;
    flex-direction: column;
    margin-left: 0.4rem;
    padding-left: 1rem;
    border-left: 2px solid ${Color.background};
  }

  .MuiIconButton-label svg {
    width: 18px;
    height: 18px;
  }
`;

const ItemComponent = memo<ItemComponentProps>(
  ({ componentId, id, option, childDictionary, onSelect, onOpen }) => {
    const { name, isSelected, selectedChildCount, children } = option;
    const isParent = children.length > 0;

    const isIndeterminate = !isSelected && !!selectedChildCount;

    const directChildren = Object.values(childDictionary).filter((item) => item.level === option.level + 1);

    const renderSuboption = (option: SelectOption) => (
      <ItemComponent
        key={option.id}
        componentId={componentId}
        id={option.id}
        option={{ ...option }}
        childDictionary={option.children.reduce((dictionary, id) => ({ ...dictionary, [id]: childDictionary[id] }), {})}
        onSelect={onSelect}
        onOpen={onOpen}
      />
    );

    if (isParent) {
      return (
        <CheckboxRow key={id}>
          <FormControlLabel
            control={<Checkbox name={id} color="primary" indeterminate={isIndeterminate} />}
            label={name}
            checked={isSelected}
            onChange={onSelect(id)}
          />

          <div>{directChildren.map(renderSuboption)}</div>
        </CheckboxRow>
      );
    } else {
      return (
        <CheckboxRow key={id}>
          <FormControlLabel control={<Checkbox name={id} color="primary" />} label={name} checked={isSelected} onChange={onSelect(id)} />
        </CheckboxRow>
      );
    }
  },
  (prev, next) => {
    if (prev.componentId !== next.componentId) return false;
    if (prev.id !== next.id) return false;

    if (!compareSelectItems(prev.option, next.option)) {
      return false;
    }

    // Is required because checkbox list renders children withing parent (not good)
    for (const child of Object.keys(next.childDictionary)) {
      if (!prev.childDictionary[child]) return false;
      if (!compareSelectItems(prev.childDictionary[child], next.childDictionary[child])) {
        return false;
      }
    }

    return true;
  }
);

export type CheckboxSelectProps = SelectProps & { style?: CSSProperties };

export const CheckboxSelect: FC<CheckboxSelectProps> = ({ style = {}, ...props }) => {
  /** Render a list item */
  const renderListItem = (
    option: SelectOption,
    itemDictionary: SelectItemDictionary,
    handleSelect: (id: string) => (event?: any) => void,
    handleOpen: (id: string) => (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
  ) => {
    const { id, index } = option;
    const { id: componentId } = props;

    const itemProps: ItemComponentProps = {
      componentId,
      id,
      option,
      childDictionary: option.children.reduce((dictionary, id) => ({ ...dictionary, [id]: itemDictionary[id] }), {}),

      onSelect: handleSelect,
      onOpen: handleOpen,
    };

    return <ItemComponent {...itemProps} key={`${componentId}-option-${index}`} />;
  };

  /** Render all list items */
  const renderListItems = (
    items: SelectOption[],
    itemDictionary: SelectItemDictionary,
    handleSelect: (id: string) => (event?: any) => void,
    handleOpen: (id: string) => (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
  ) => {
    const renderedItems = [];
    for (const item of items) {
      if (item.parents.length === 0) {
        renderedItems.push(renderListItem(item, itemDictionary, handleSelect, handleOpen));
      }
    }
    return renderedItems;
  };

  return (
    <SelectBase
      {...props}
      containerComponent={<Container />}
      listComponent={<List style={style} />}
      itemComponent={ItemComponent}
      isParentOpenable={false}
      renderListItems={renderListItems}
    />
  );
};
