import React, { FC, KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Color, borderRadius, mediaQuery } from '../Theme';
import { searchState, useClearSearch } from '../../context/searchStore';
import { useFieldState } from '../../lib/state';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchDirty } from '../../context/advancedSearchStore';
import { useRouter } from 'next/router';
import { Header } from '../Header';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { useSearch } from '../../context/useSearch';

const Keybinding = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  padding: 2px 4px 2px 7px;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: ${Color.disabled};
  border-radius: ${borderRadius}px;
  color: white;
  opacity: 0.4;
  user-select: none;
  pointer-events: none;

  @media (max-width: 640px) {
    display: none;
  }

  svg {
    margin-left: 3px;
    width: 20px;
    height: 20px;
  }
`;

const OutlinedSearchInput = styled(OutlinedInput)`
  &:not(.Mui-focused) {
    ${Keybinding} {
      display: none;
    }
  }

  > input {
    padding-left: 0.45rem !important;
  }

  ${mediaQuery.small(css`
    display: block;

    > input {
      padding-left: 3.9rem !important;
    }
  `)}
`;

const SearchInputLabel = styled.div`
  position: absolute;
  display: none;
  user-select: none;
  pointer-events: none;

  h2 {
    margin: 0 0 0 0.4rem;
    font-size: 1.06rem;
    font-weight: bold;
  }

  ${mediaQuery.small(css`
    display: block;
  `)}
`;

let searchUpdateTimeout: number = null;

export interface OmnibarSearchInputProps {}

export const OmnibarSearchInput: FC<OmnibarSearchInputProps> = ({}) => {
  const intl = useIntl();
  const { pathname } = useRouter();
  const { doSearch } = useSearch();
  const { doClearSearch } = useClearSearch();
  const isAdvancedSearchDirty = useSearchDirty();
  const isMedium = useBreakPoint('medium');

  const searchInput = useFieldState(searchState, 'searchText');

  const isSearchDirty = searchInput.value || isAdvancedSearchDirty;

  const placeholderMessageLong = intl.formatMessage({
    id: 'search.input.placeholder.long',
    defaultMessage: 'Toimiala, yrityksen nimi, toimialue, kohdetyyppi…',
  });

  const placeholderMessageShort = intl.formatMessage({
    id: 'search.input.placeholder.short',
    defaultMessage: 'Yrityksen nimi, toimialue…',
  });

  const placeholderMessage = isMedium ? placeholderMessageShort : placeholderMessageLong;

  const clearTooltipMessage = intl.formatMessage({
    id: 'search.input.clearButton.tooltip',
    defaultMessage: 'Nollaa kaikki rajaukset',
  });

  const handleSearchKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      /** Unfocus so the quick search list does not cover the actual search results */
      setTimeout(() => {
        (document.activeElement as HTMLInputElement).blur();
      }, 0);

      /*
      if (selected !== null) {
        handleQuickSearchResult();
        return;
      }
      */

      doSearch();

      return;
    }

    // @ts-ignore
    const isSelectorRight = event.target.selectionStart === event.target.value.length;

    if (event.key === 'ArrowDown' && isSelectorRight) {
      /*
      event.preventDefault();

      if (selected === null) {
        setSelected(0);
      } else {
        setSelected(Math.min(selected + 1, quickSearchData.length - 1));
      }
      */

      return;
    }

    if (event.key === 'ArrowUp' /* && selected !== null*/) {
      /*
      event.preventDefault();

      if (selected === 0) {
        setSelected(null);
      } else {
        setSelected(selected - 1);
      }
      */

      return;
    }
  };

  const handleSearchClick = () => doSearch();

  const handleSearchFocus = () => {
    // setHasFocus(true);
    // setSelected(null);
  };

  const handleSearchBlur = () => {
    // setTimeout(() => setHasFocus(false), 100);
  };

  /*
  const handleQuickSearchResult = () => {
    if (selected !== null) {
      const company = quickSearchData[selected];
      const hash = encodeCompanyIDHash(company.companyID);
      const name = slugify(company.name);
      router.push('/company/[hash]/[slug]', `/company/${hash}/${name}`);
    }
    return;
  };

  const handleQuickSearchListClick = () => {
    handleQuickSearchResult();
    return;
  };
  */

  const handleSearchClear = () => {
    doClearSearch();

    if (pathname !== '/') {
      doSearch('');
    }
  };

  return (
    <>
      <OutlinedSearchInput
        fullWidth
        placeholder={placeholderMessage}
        {...searchInput}
        onKeyUp={handleSearchKeyUp}
        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
        // FIXME: Enable when quick search has been implemented
        // role="combobox"
        // aria-haspopup="listbox"
        // aria-owns="quick-search-list"
        // aria-expanded={false /*isListOpen*/}
        role="search"
        inputProps={{
          id: 'search-input',
          // FIXME: Enable when quick search has been implemented
          // 'aria-autocomplete': 'list',
          // 'aria-controls': 'quick-search-list',
          // 'aria-activedescendant': selected !== null ? `quick-search-option-${selected}` : '',
        }}
        style={{ paddingRight: 4 }}
        endAdornment={
          <InputAdornment position="end">
            {/*
              <IconButton aria-label="search" onClick={handleSearchClick} edge="end">
                <MagnifyIcon />
              </IconButton>
              */}
            <Keybinding>
              ENTER <MagnifyIcon />
            </Keybinding>

            {isSearchDirty && (
              <Tooltip title={clearTooltipMessage} placement="left">
                <IconButton aria-label={clearTooltipMessage.toLowerCase()} onClick={handleSearchClear}>
                  <CloseCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </InputAdornment>
        }
        startAdornment={
          <SearchInputLabel aria-hidden>
            <Header>
              <FormattedMessage id="search.input.label" defaultMessage="Haku" />
            </Header>
          </SearchInputLabel>
        }
      />

      {/*
        <Styled.List
          onClick={handleQuickSearchListClick}
          id="quick-search-list"
          role="listbox"
          isListOpen={isListOpen && quickSearchData.length > 0}
        >
          {quickSearchData.map((row, index) => (
            <CompanyListRow
              id={`quick-search-option-${index}`}
              key={`${row.companyID}`}
              company={row}
              selected={selected === index}
              small
            />
          ))}
        </Styled.List>
        */}
    </>
  );
};
