import React, { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuUpIcon from 'mdi-react/MenuUpIcon';
import MenuRightIcon from 'mdi-react/MenuRightIcon';
import { Color, cardStyles, layoutStyles, borderRadius, mediaQuery } from '../Theme';
import { OmnibarSelect } from './OmnibarSelect';
import { OmnibarButton } from './OmnibarButton';
import { OmnibarSearchButton, OmnibarSearchButtonStyle } from './OmnibarSearchButton';
import { OmnibarAdvanced, getAdvancedOptionCount } from './OmnibarAdvanced';
import { FormattedMessage } from 'react-intl';
import { OmnibarSearchInput } from './OmnibarSearchInput';
import { useSearch } from '../../context/useSearch';
import { useUser } from '../../context/useUser';
import { openPaywall } from '../../context/paywallStore';

interface ContainerProps {
  fullPage: boolean;
  isListOpen: boolean;
  hasMobileBar: boolean;
}

const Styled = {
  Omnibar: styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    ${cardStyles}

    ${(props) =>
      props.fullPage &&
      css`
        margin-bottom: 0;
        max-width: 100%;
      `}

    ${(props) =>
      props.isListOpen &&
      css`
        border-radius: ${borderRadius}px ${borderRadius}px ${borderRadius}px 0;
      `}

    ${({ hasMobileBar }) =>
      hasMobileBar &&
      mediaQuery.small(css`
        display: none;
      `)}
  `,
  Container: styled.div<ContainerProps>`
    display: flex;
    position: relative;
    height: 66px;
    ${layoutStyles}
    padding-left: 0 !important;
    padding-right: 0 !important;

    .search-button span {
      display: none;
    }

    > .MuiOutlinedInput-root {
      .MuiInputAdornment-root {
        margin-right: 0.4rem;
      }

      input {
        padding-left: 1.6rem;
        font-size: 1.06rem;
      }
    }

    fieldset {
      border-width: 2px;
      border-color: transparent;
    }

    > button {
      margin-left: -2px;

      &:last-of-type {
        border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
        border-right-color: ${(props) => (props.fullPage ? Color.background : 'transparent')};
      }
    }

    > div:first-of-type {
      flex: 1;
      border-radius: ${borderRadius}px 0 0 ${borderRadius}px;

      > div:not(.badge) {
        border-radius: ${borderRadius}px 0 0 ${borderRadius}px;
      }
    }

    .MuiInputBase-root,
    .MuiFormControl-root,
    button {
      &:hover,
      &:active,
      &:focus {
        z-index: 3;
      }
    }

    .Mui-focused,
    button:focus,
    button:active {
      z-index: 3;
    }

    > hr {
      position: relative;
      align-self: stretch;
      margin: 0;
      width: 0;
      height: 100%;
      border: none;
      overflow: visible;

      &:first-of-type {
        &::after {
          display: ${(props) => (props.fullPage ? 'block' : 'none')};
          left: 0;
        }
      }

      &::after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: -2px;
        width: 2px;
        height: 100%;
        background-color: ${Color.background};
      }
    }

    ${mediaQuery.small(css`
      flex-direction: column;
      height: auto;

      > hr:first-of-type {
        display: none;
      }

      .search-button span {
        display: inline;
      }

      ${OmnibarSearchButtonStyle} {
        width: 100%;
      }

      > .MuiInputBase-root {
        flex: none !important;
        width: 100%;
        height: 66px;
        border-radius: 0px;

        input {
          flex: 1;
          width: 100%;
        }

        fieldset {
          border-color: transparent;
        }
      }

      > button {
        width: 100%;
        margin-top: -2px !important;
        margin-left: 0;
        height: 66px;
        border-radius: 0 !important;
        border-color: transparent !important;
        border-top-color: ${Color.background} !important;

        &:last-child {
          border-radius: 0 0 ${borderRadius}px ${borderRadius}px !important;
        }
      }

      > hr {
        width: 100%;
        height: 0;

        &::after {
          top: -2px;
          left: 0;
          height: 2px;
          width: 100%;
        }
      }
    `)}

    ${(props) =>
      props.isListOpen &&
      css`
        > div:first-of-type {
          border-radius: ${borderRadius}px 0 0 0;
        }
      `}

    ${(props) =>
      props.fullPage &&
      css`
        > div {
          border-radius: 0 !important;
        }

        > div > div {
          border-radius: 0 !important;
        }

        > button {
          border-radius: 0 !important;
        }
      `}
  `,
  List: styled.div<{ isListOpen: boolean }>`
    display: ${(props) => (props.isListOpen ? 'block' : 'none')};
    position: absolute;
    left: 0;
    top: calc(100% - 2px);
    ${cardStyles}
    width: 600px;
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
    z-index: 2;

    box-shadow: 0 6px 6px rgba(149, 157, 165, 0.15);

    > div:first-of-type a {
      border-radius: 0;
    }

    @media (max-width: 640px) {
      top: calc(100% - (66px * 2));
      width: 100%;
    }
  `,
  Advanced: styled.div`
    width: 100%;
    background-color: ${Color.white};

    &::before {
      position: absolute;
      content: '';
      display: block;
      margin-top: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${Color.background};
    }

    > div {
      ${layoutStyles}
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  `,
};

export interface OmnibarProps {
  fullPage?: boolean;
  hasMobileBar?: boolean;
}

export const Omnibar: FC<OmnibarProps> = ({ fullPage = false, hasMobileBar = false }) => {
  const router = useRouter();
  const { hasPremium } = useUser();
  const { doSearch, set, isAdvancedSearchOpen } = useSearch();

  // Close the advanced search when navigating between pages
  useEffect(() => {
    set({ isAdvancedSearchOpen: false });
  }, [router.pathname]);

  const isListOpen = false; // searchText.length > 0 && quickSearchData.length && hasFocus;

  const containerProps: ContainerProps = { fullPage, isListOpen, hasMobileBar };

  const handleSearchClick = () => doSearch();

  const handleFilterPopupClose = (changed: boolean) => {
    if (!changed) return;
    if (router.pathname === '/') return;

    // Add a small delay for smooth close animation
    setTimeout(() => {
      handleSearchClick();
    }, 10);
  };

  const handleMoreClick = () => {
    if (!hasPremium && openPaywall('advanced')) return;
    set({ isAdvancedSearchOpen: !isAdvancedSearchOpen });
  };

  return (
    <Styled.Omnibar className="omnibar" {...containerProps}>
      <Styled.Container {...containerProps}>
        <hr />

        <OmnibarSearchInput />

        <hr />

        <OmnibarSelect id="toimialue" title="Toimialue" path="operationalAreas" onClose={handleFilterPopupClose} />

        <hr />

        <OmnibarSelect id="toimialaluokat" title="Toimialaluokat" path="talo80" onClose={handleFilterPopupClose} />

        <hr />

        <OmnibarButton
          icon={<TuneIcon />}
          iconRight={isAdvancedSearchOpen ? <MenuUpIcon /> : <MenuDownIcon />}
          count={getAdvancedOptionCount()}
          onClick={handleMoreClick}
        >
          <FormattedMessage id="search.advanced.showMoreButton" defaultMessage="Lisää" />
        </OmnibarButton>

        <OmnibarSearchButton
          className="search-button"
          icon={<MagnifyIcon />}
          iconRight={<MenuRightIcon style={{ marginLeft: -4 }} />}
          onClick={handleSearchClick}
        >
          <span>
            <FormattedMessage id="search.searchButton" defaultMessage="Hae" />
          </span>
        </OmnibarSearchButton>
      </Styled.Container>

      <Collapse in={isAdvancedSearchOpen} timeout="auto" unmountOnExit>
        <Styled.Advanced>
          <OmnibarAdvanced />
        </Styled.Advanced>
      </Collapse>
    </Styled.Omnibar>
  );
};
