import React, { FC } from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { useIntl } from 'react-intl';

const StyledIconButton = styled(IconButton)`
  .MuiIconButton-label {
    width: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ClearFilterButton: FC<IconButtonProps> = (props) => {
  const intl = useIntl();

  const tooltipMessage = intl.formatMessage({
    id: 'search.advanced.clearButton.tooltip',
    defaultMessage: 'Poista valinnat',
  });

  return (
    <Tooltip title={tooltipMessage} placement="right">
      <StyledIconButton aria-label={tooltipMessage.toLowerCase()} size="small" style={{ width: 22, height: 22, padding: 0 }} {...props}>
        <CloseCircleOutlineIcon />
      </StyledIconButton>
    </Tooltip>
  );
};
