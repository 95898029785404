import { useIntl } from 'react-intl';

/** Translations for all company field names */
export const useCompanyDictionary = () => {
  const intl = useIntl();

  const dictionary = {
    companyID: '',
    name: intl.formatMessage({
      id: 'company.name',
      defaultMessage: 'Toiminimi',
    }),
    marketingName: intl.formatMessage({
      id: 'company.marketingName',
      defaultMessage: 'Markkinointinimi',
    }),
    businessID: '',
    description: intl.formatMessage({
      id: 'company.description',
      defaultMessage: 'Yritysesittely',
    }),
    dateEstablished: intl.formatMessage({
      id: 'company.dateEstablished',
      defaultMessage: 'Perustamisvuosi',
    }),
    timeCreate: '',
    phone: intl.formatMessage({
      id: 'company.phone',
      defaultMessage: 'Puhelinnumero',
    }),
    fax: intl.formatMessage({
      id: 'company.fax',
      defaultMessage: 'Faksi',
    }),
    email: intl.formatMessage({
      id: 'company.email',
      defaultMessage: 'Sähköpostiosoite',
    }),
    homepage: intl.formatMessage({
      id: 'company.homepage',
      defaultMessage: 'Kotisivu',
    }),
    numberOfEmployees: intl.formatMessage({
      id: 'company.numberOfEmployees',
      defaultMessage: 'Henkilöstömäärä',
    }),
    turnover: intl.formatMessage({
      id: 'company.turnover',
      defaultMessage: 'Liikevaihto',
    }),
    postalAddress: intl.formatMessage({
      id: 'company.postalAddress',
      defaultMessage: 'Postiosoite',
    }),
    visitingAddress: intl.formatMessage({
      id: 'company.visitingAddress',
      defaultMessage: 'Vierailuosoite',
    }),
    tol: intl.formatMessage({
      id: 'company.tol',
      defaultMessage: 'Päätoimiala (TOL2008)',
    }),
    businessType: '',
    domicile: intl.formatMessage({
      id: 'company.domicile',
      defaultMessage: 'Kotipaikka',
    }),
    projectTypes: intl.formatMessage({
      id: 'company.projectTypes',
      defaultMessage: 'Hanketyypit',
    }),
    contractingForms: intl.formatMessage({
      id: 'company.contractingForms',
      defaultMessage: 'Urakointimuodot',
    }),
    customerTargetGroups: intl.formatMessage({
      id: 'company.customerTargetGroups',
      defaultMessage: 'Asiakaskohderyhmät',
    }),
    machineryTypes: intl.formatMessage({
      id: 'company.machineryTypes',
      defaultMessage: 'Konekanta',
    }),
    taitoCompetences: intl.formatMessage({
      id: 'company.taitoCompetences',
      defaultMessage: 'Taitorekisteri',
    }),
    languages: intl.formatMessage({
      id: 'company.languages',
      defaultMessage: 'Työmaakielet',
    }),
    talo80: intl.formatMessage({
      id: 'company.talo80',
      defaultMessage: 'Toimialaluokat',
    }),
    operationalAreas: intl.formatMessage({
      id: 'company.operationalAreas',
      defaultMessage: 'Toimialueet',
    }),
    locations: intl.formatMessage({
      id: 'company.locations',
      defaultMessage: 'Toimipisteet',
    }),
    contactPersons: intl.formatMessage({
      id: 'company.contactPersons',
      defaultMessage: 'Yhteyshenkilöt',
    }),
    // @ts-ignore
    sales: intl.formatMessage({
      id: 'company.sales',
      defaultMessage: 'Myynti',
    }),
    references: intl.formatMessage({
      id: 'company.references',
      defaultMessage: 'Referenssit',
    }),
    referencesDescription: intl.formatMessage({
      id: 'company.references.description',
      defaultMessage: 'Kuvaus',
    }),
    referencesValue: intl.formatMessage({
      id: 'company.references.value',
      defaultMessage: 'Urakan arvo',
    }),
    referencesDate: intl.formatMessage({
      id: 'company.references.date',
      defaultMessage: 'Ajankohta',
    }),
    referencesCustomer: intl.formatMessage({
      id: 'company.references.customer',
      defaultMessage: 'Tilaaja',
    }),
  } as const;

  return dictionary;
};
