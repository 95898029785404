import { FC, CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Color, mediaQuery } from '../Theme';

export const Grid = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;

  ${mediaQuery.small(css`
    flex-direction: column;
    padding: 1rem;
  `)}
`;

export const Column = styled.div`
  flex: 1;

  &:not(:last-of-type) {
    padding-right: 1rem;
    border-right: 2px solid ${Color.background};
  }

  &:not(:first-of-type) {
    padding-left: 1rem;
  }

  ${mediaQuery.small(css`
    &:not(:last-of-type) {
      padding-right: 0;
      border-right: none;
    }

    &:not(:first-of-type) {
      padding-left: 0;
    }
  `)}

  ${Grid} {
    padding: 0;
  }
`;

export const ItemStyle = styled.div`
  padding: 1.2rem;
`;

export const Item: FC<{ show?: any }> = ({ show = true, children }) => {
  return !!show ? <ItemStyle>{children}</ItemStyle> : null;
};

export const SpacerStyle = styled.div`
  margin: 0.6rem 0;
  height: 2px;
  background-color: ${Color.background};
`;

export const Spacer: FC<{ show?: any; style?: CSSProperties }> = ({ show = true, style }) => {
  return !!show ? <SpacerStyle style={style} /> : null;
};

/** Has to be here, or the SSR won't work right... */
export const BasicInfoGrid = styled(Grid)`
  position: relative;

  > .MuiButton-root {
    position: absolute;
    top: 1.1rem;
    right: 1rem;
  }
`;
