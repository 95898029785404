import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { PremiumIcon } from '../PremiumIcon';
import { Color } from '../Theme';

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${Color.black};
  opacity: 0.8;

  > .premium-icon {
    margin-left: 0.2rem;
  }
`;

const StyledField = styled.div<{ large: boolean; chip: boolean }>`
  .value {
    display: ${({ chip }) => (chip ? 'block' : 'flex')};
    align-items: center;
    line-height: ${({ large }) => (large ? 1.9 : 1.4)}rem;
    margin-top: 0.3rem;
    font-size: ${({ large }) => (large ? 1.5 : 1.0)}rem;
    font-weight: ${({ large }) => (large ? 500 : 400)};

    svg:first-child {
      margin-right: 0.4rem;
      margin-bottom: -1px;
    }

    span {
      margin-right: 0.4rem;
    }
  }

  .empty {
    line-height: ${({ large }) => (large ? 1.9 : 1.4)}rem;
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.4;
  }

  a {
    display: inline-flex;
    align-items: center;

    /* NOTE! This should probably be in the main stylesheet, or is it an exception? */
    color: ${Color.primary};
  }
`;
export interface FieldProps {
  /** Field label */
  label: React.ReactNode;
  /** Larger font */
  large?: boolean;
  /** Render premium diamond icon & block value if loading is finished */
  premiumOnly?: boolean;
  /** Render a loading skeleton */
  loading?: boolean;
  /** Render "not available" message */
  empty?: boolean;
  /** Does field show value chips */
  chip?: boolean;
  /** User premium state */
  premium?: boolean;
}

/** Company field with label and value */
export const Field: FC<FieldProps> = ({ label, premiumOnly, large, loading, empty, chip = false, premium = false, children }) => {
  const isLoading = loading;
  const isPremium = premium;

  let content = children;

  if (isLoading && premiumOnly) {
    // If is loading an is premium only, render a loading indicator
    content = chip ? (
      <>
        <SkeletonChip loading width="20%" />
        <SkeletonChip loading width="28%" />
        <SkeletonChip loading width="18%" />
      </>
    ) : (
      <Skeleton width="30%" loading />
    );
  } else if (premiumOnly && empty && !isPremium) {
    // If field is premium only, but the user is not premium, render gray box
    content = chip ? (
      <>
        <SkeletonChip width="20%" />
        <SkeletonChip width="28%" />
        <SkeletonChip width="18%" />
      </>
    ) : (
      <Skeleton width="30%" />
    );
  } else if (empty) {
    // If field is marked as "empty", render the "not available" state
    content = (
      <i className="empty">
        <FormattedMessage id="company.emptyField" defaultMessage="Ei saatavilla" />
      </i>
    );
  }

  return (
    <StyledField large={large} chip={chip}>
      <StyledLabel className="label">
        {label} {premiumOnly && <PremiumIcon />}
      </StyledLabel>
      <div className="value">{content}</div>
    </StyledField>
  );
};

/** Show skeleton value
 *  Use to show skeleton value
 * */
export const Skeleton = ({
  width = 'auto',
  height = 'auto',
  loading = false,
}: {
  width?: number | string;
  height?: number | string;
  loading?: boolean;
}) => <MuiSkeleton animation={loading ? 'wave' : false} width={width} height={height}></MuiSkeleton>;

/** Wrap field value in quotes */
export const Quote = styled.div`
  &:before,
  &:after {
    display: inline-block;
    opacity: 0.4;
  }
  &:before {
    margin-right: 0.1rem;
    content: '“';
  }
  &:after {
    margin-left: 0.1rem;
    content: '”';
  }
`;

/** Render info chip */
export const Chip = styled.div<{ width?: number | string; selected?: boolean }>`
  width: ${({ width }) => width};
  display: inline-block;
  position: relative;
  margin-top: 0.4rem;
  margin-right: 0.4rem;
  padding: 0.3rem 0.6rem;
  background-color: ${({ selected }) => (selected ? Color.primary : 'rgb(224, 224, 224)')};
  color: ${({ selected }) => (selected ? Color.white : Color.black)};
  border-radius: 3px;

  .skeleton {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: auto;
    border-radius: 3px;
    background: none;
  }
`;

/** Render skeleton info chip
 *  Implemented as MUI Skeleton rendered inside a normal chip to get exact same dimensions
 * */
export const SkeletonChip = ({ width = 'auto', loading = false }: { width?: number | string; loading?: boolean }) => (
  <Chip width={width}>
    &nbsp;
    <MuiSkeleton className="skeleton" animation={loading ? 'wave' : false} variant="rect"></MuiSkeleton>
  </Chip>
);
