import { useRouter } from 'next/router';
import { useScopedState } from '../lib/state';
import { SearchProcessState, searchState } from './searchStore';
import { checkSearchCache, restoreSearchStateFromCache } from './searchCache';

/** Hook to make searching from any page easy */
export const useSearch = () => {
  const router = useRouter();
  const { set, isAdvancedSearchOpen } = useScopedState(searchState, ['isAdvancedSearchOpen']);

  /**
   * Do a search
   * - Handles cache
   * - Handles URL state
   */
  const doSearch = async (searchText: string = null) => {
    const { route } = router;
    const actualSearchText = searchText ?? searchState.getState().searchText;

    // Close the "advanced search" menu
    set({ isAdvancedSearchOpen: false });

    if (route === '/search') {
      // We are at the search page, do a search
      router.push(
        {
          pathname: '/search',
          query: { q: actualSearchText },
        },
        null,
        {
          scroll: false,
        }
      );

      await searchState.getState().doSearch(actualSearchText);
    } else {
      // Switch to the search page
      set({ isSearchLoading: true, state: SearchProcessState.FRESH, searchItems: [], searchPage: 0 });

      router.push({
        pathname: '/search',
        query: { q: actualSearchText },
      });
    }
  };

  /**
   * Load more results for the current search
   * - Handles URL state
   */
  const doLoadMore = async () => {
    const { currentSearchQuery, searchPage } = searchState.getState();

    const page = searchPage + 1;
    const text = currentSearchQuery.fullTextSearch || '';

    // Switch to the search page, or update the browsing history
    router.replace(
      {
        pathname: '/search',
        query: { q: text, p: page },
      },
      null,
      {
        scroll: false,
      }
    );

    await searchState.getState().doLoadMore(page);
  };

  /**
   * Figure out what should be done when the search page is loaded / updated
   * - Restores search text
   * - Restores search page
   * - Restores scroll position
   * - Restores search results
   */
  const doInitSearch = async ({ searchText, searchPage }: { searchText: string; searchPage: number }) => {
    const { searchText: currentSearchText, state } = searchState.getState();

    // Search is already initialized, nothing to do here
    if (currentSearchText === searchText && state !== SearchProcessState.FRESH) return;

    const cacheEntry = checkSearchCache({ text: searchText, page: searchPage });

    if (cacheEntry) {
      // Cache entry found, we can restore
      restoreSearchStateFromCache(cacheEntry);
    } else {
      // No cache entry found, remove page param and do a regular search
      router.replace(
        {
          pathname: '/search',
          query: { q: searchText },
        },
        null,
        {
          scroll: true,
        }
      );

      await doSearch(searchText);
    }
  };

  return {
    doSearch,
    doLoadMore,
    doInitSearch,

    set,
    isAdvancedSearchOpen,
  };
};
