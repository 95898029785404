import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import { Header } from '../Header';
import { CheckboxSelect, CheckboxRow } from '../select/CheckboxSelect';
import { Grid, Column } from '../company/CompanyGrid';
import { Button } from '../Button';
import { useCompanyDictionary } from '../company/CompanyDictionary';
import { OmnibarSlider } from './OmnibarSlider';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { OmnibarSelect } from './OmnibarSelect';
import { Badge } from '../Badge';
import { useScopedState } from '../../lib/state';
import { staticState, StaticState } from '../../context/staticStore';
import {
  advancedSearchOptions,
  AdvancedSearchState,
  advancedSearchState,
  advancedSearchStateDefaults,
  numberOfEmployeeMarks,
  turnoverMarks,
  useSearchFilter,
} from '../../context/advancedSearchStore';
import { useSearch } from '../../context/useSearch';
import { ClearFilterButton } from './ClearFilterButton';

const CheckboxColumn = styled(Column)`
  > div .MuiFormControlLabel-root {
  }
`;

const VerticalCheckboxSelect = styled(CheckboxSelect)`
  .select-list {
    flex-direction: column;
    align-items: stretch;
    max-height: 320px;
  }

  ${CheckboxRow} {
    flex: none;
  }
`;

const Actions = styled.div<{ page: boolean }>`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
  padding: ${({ page }) => (page ? '1rem' : '0 1rem 0 0')};
`;

/** Get count of all advanced options */
export const getAdvancedOptionCount = () => {
  const { contractingForms, languages, machineryTypes, projectTypes, taitoCompetences, targetGroups, tol2008 } =
    advancedSearchState.getState();

  /** Enforce that all advanced search options are handled here */
  const advancedOptions: Pick<AdvancedSearchState, typeof advancedSearchOptions[number]> = {
    contractingForms,
    languages,
    machineryTypes,
    projectTypes,
    taitoCompetences,
    targetGroups,
    tol2008,
  };

  return Object.values(advancedOptions).reduce((count, state) => count + state.count, 0);
};

/** Helper to quickly add search context items as checkbox selects */
const OmnibarAdvancedSelect: FC<{
  title: string;
  path: keyof StaticState;
  style?: CSSProperties;
  vertical?: boolean;
  chip?: boolean;
}> = ({ title, path, style = {}, vertical = false, chip = false }) => {
  const items = useScopedState(staticState, [path])[path];
  const { state, set } = useSearchFilter(path);

  const { selected, count } = state;

  const handleFilterClear = () => {
    set({ selected: [], count: 0 });
  };

  const selectProps = {
    id: `advanced-${path}-select`,
    items,
    selected,
    onSelect: set,
    style,
  };

  return (
    <>
      <Header small>
        {title}
        {count > 0 && (
          <>
            <Badge>{count}</Badge>
            <ClearFilterButton onClick={handleFilterClear} />
          </>
        )}
      </Header>

      {!chip && (vertical ? <VerticalCheckboxSelect {...selectProps} /> : <CheckboxSelect {...selectProps} />)}
      {!!chip && <OmnibarSelect id={selectProps.id} title={title} path={path} chip />}
    </>
  );
};

/** Number of employees slider */
const getValueTextForNumberOfEmployees = (value: number) =>
  `${value}${value === numberOfEmployeeMarks[numberOfEmployeeMarks.length - 1] ? '+' : ''} hlö.`;

const getValueTextForDateEstablished = (value: number) =>
  `v. ${value}${value === numberOfEmployeeMarks[numberOfEmployeeMarks.length - 1] ? '+' : ''}`;
/** Sales per year slider */
const numberWithSpaces = (x: number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
const getValueTextForTurnover = (value: number) => {
  if (value < 1000) return `${numberWithSpaces(value * 1000)} ${value === turnoverMarks[turnoverMarks.length - 1] ? '+' : ''} €`;
  return `${value / 1000}milj.${value === turnoverMarks[turnoverMarks.length - 1] ? '+' : ''} €`;
};

export const OmnibarAdvanced: FC<{ page?: boolean }> = ({ page = false }) => {
  const dictiorary = useCompanyDictionary();
  const { set } = useScopedState(advancedSearchState, []);
  const { doSearch } = useSearch();

  const handleSearchClear = () => {
    set({ ...advancedSearchStateDefaults });
  };

  return (
    <div>
      <Grid>
        <Column>
          <OmnibarAdvancedSelect title={dictiorary.projectTypes} path="projectTypes" />
        </Column>
      </Grid>

      <Grid>
        <CheckboxColumn>
          <OmnibarSlider
            title={dictiorary.numberOfEmployees}
            path="numberOfEmployees"
            values={numberOfEmployeeMarks}
            valueToLabel={getValueTextForNumberOfEmployees}
          />
        </CheckboxColumn>
        <CheckboxColumn>
          <OmnibarSlider title={dictiorary.turnover} path="turnover" values={turnoverMarks} valueToLabel={getValueTextForTurnover} />
        </CheckboxColumn>
      </Grid>

      {/*
      <Grid>
        <CheckboxColumn>
          <OmnibarSlider
            title={dictiorary.dateEstablished}
            path="dateEstablished"
            values={dateEstablishedMarks}
            valueToLabel={getValueTextForDateEstablished}
          />
        </CheckboxColumn>
      </Grid>
      */}

      {/*
      <Grid>
        <CheckboxColumn>
          <OmnibarSlider
            title={dictiorary.dateEstablished}
            path="dateEstablished"
            values={dateEstablishedMarks}
            valueToLabel={getValueTextForDateEstablished}
          />
        </CheckboxColumn>
      </Grid
      */}

      <Grid>
        <CheckboxColumn>
          <OmnibarAdvancedSelect title={dictiorary.customerTargetGroups} path="targetGroups" vertical />
        </CheckboxColumn>
        <CheckboxColumn>
          <OmnibarAdvancedSelect title={dictiorary.contractingForms} path="contractingForms" vertical />
        </CheckboxColumn>
        <CheckboxColumn>
          <OmnibarAdvancedSelect title={dictiorary.languages} path="languages" vertical />
        </CheckboxColumn>
      </Grid>
      <Grid>
        {/*
        <Column>
          <OmnibarAdvancedSelect title={dictiorary.projectTypes} path="projectTypes" style={{ maxHeight: 700 }} vertical chip />
        </Column>
        */}
        <Column>
          <OmnibarAdvancedSelect title={dictiorary.taitoCompetences} path="taitoCompetences" style={{ maxHeight: 700 }} vertical chip />
        </Column>

        <Column>
          <OmnibarAdvancedSelect title={dictiorary.tol} path="tol2008" style={{ maxHeight: 700 }} vertical chip />
        </Column>
      </Grid>
      {/*
      <Grid>
        <Column>
          <OmnibarAdvancedSelect title={dictiorary.machineryTypes} path="machineryTypes" style={{ maxHeight: 700 }} vertical chip />
        </Column>
      </Grid>
      */}

      <Actions page={page}>
        <Button startIcon={<MagnifyIcon />} onClick={() => doSearch()}>
          Hae
        </Button>
        <Button variant="text" onClick={handleSearchClear}>
          Tyhjennä haku
        </Button>
      </Actions>
    </div>
  );
};
