import { FC, MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { borderRadius } from '../Theme';
import { ListSelect } from '../select/ListSelect';
import { OmnibarButton } from './OmnibarButton';
import { FormattedMessage } from 'react-intl';
import { Chip } from '../company/CompanyFieldAndHelpers';
import FilterPlusIcon from 'mdi-react/FilterPlusIcon';
import { Button } from '../Button';
import { useSearchFilter } from '../../context/advancedSearchStore';
import { useScopedState } from '../../lib/state';
import { StaticState, staticState } from '../../context/staticStore';
import { useUser } from '../../context/useUser';
import { openPaywall } from '../../context/paywallStore';

const Styled = {
  Popover: styled(Popover)`
    .MuiPopover-paper {
      display: flex;
      flex-direction: column;
      width: 400px;
      box-shadow: 0 6px 32px rgba(149, 157, 165, 0.6);
      border-radius: 0 0 ${borderRadius}px ${borderRadius}px;

      .MuiInputBase-root {
        border-radius: 0;
      }
    }
  `,
};

const ChipList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 0.5rem;
`;

const ChipActions = styled.div`
  display: flex;
  align-items: center;
`;

export const OmnibarSelect: FC<{
  id: string;
  title: string;
  path: keyof StaticState;
  chip?: boolean;
  onClose?: (changed: boolean) => void;
}> = ({ id, title, path, chip, onClose }) => {
  const { hasPremium } = useUser();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [previousSelected, setPreviousSelected] = useState<string | null>('');

  const items = useScopedState(staticState, [path])[path];
  const { state, set } = useSearchFilter(path);

  const { selected, count } = state;

  const open = Boolean(anchorEl);

  useEffect(() => {
    setPreviousSelected(selected.join(','));
  }, [open]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    // If user has no premium access, open paywall
    if (!hasPremium && openPaywall('filter')) return;

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    const selectedString = selected.join(',');
    const isChanged = previousSelected !== selectedString;

    setPreviousSelected(selectedString);

    if (onClose) onClose(isChanged);
    setAnchorEl(null);
  };

  const selectedItems = !!chip ? items.filter((item) => selected.includes(`${item.id}`)) : [];

  return (
    <>
      {!chip && (
        <OmnibarButton count={count} onClick={handleClick}>
          {title}
        </OmnibarButton>
      )}

      {!!chip && (
        <>
          <ChipList>
            {selectedItems.map((item) => (
              <Chip key={item.id}>{item.name}</Chip>
            ))}
          </ChipList>

          <ChipActions>
            <Button startIcon={<FilterPlusIcon />} onClick={handleClick}>
              <FormattedMessage id="search.advanced.chipAddButton" defaultMessage="Lisää suodatin" />
            </Button>
          </ChipActions>
        </>
      )}

      <Styled.Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ height: '50vh', minHeight: 400 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListSelect id={`omnibar-${id}-select`} selected={selected} onSelect={set} transparent showCount={false} items={items} />
      </Styled.Popover>
    </>
  );
};
