import { FC } from 'react';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import { Header } from '../Header';
import { withStyles } from '@material-ui/core/styles';
import { advancedSearchState, SearchSliderOptionsState } from '../../context/advancedSearchStore';
import { useScopedState } from '../../lib/state';

const Container = styled.div`
  padding: 0 1rem;
  box-sizing: border-box;
`;

const ConstleSlider = withStyles({
  thumb: {
    /*
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
    */
  },
  valueLabel: {
    left: -34,
    top: 28,
    width: 80,
    textAlign: 'center',
    fontSize: '1rem',
    '& *': {
      background: 'transparent',
      color: '#000',
      transform: 'none',
      width: '100%',
    },
  },
})(Slider);

export interface OmnibarSliderProps {
  title: string;
  path: keyof SearchSliderOptionsState;
  values: number[];
  valueToLabel: (value: number) => string;
}

export const OmnibarSlider: FC<OmnibarSliderProps> = ({ title, path, values = [], valueToLabel }) => {
  const { set, ...state } = useScopedState(advancedSearchState, [path]);

  const value = state[path];

  const handleChange = (event, newValue) => {
    set({ [path]: newValue } as any);
  };

  return (
    <>
      <Header small>{title}</Header>

      <Container>
        <ConstleSlider
          value={value}
          onChange={handleChange}
          getAriaValueText={valueToLabel}
          valueLabelFormat={valueToLabel}
          aria-labelledby="discrete-slider"
          marks
          min={0}
          max={values.length - 1}
          scale={(x) => values[x]}
          valueLabelDisplay="on"
        />
      </Container>
    </>
  );
};
