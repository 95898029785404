import { FC, MouseEvent } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import { Color } from '../Theme';
import { Badge } from '../Badge';

const Styled = {
  Button: styled.button<{ primary: boolean }>`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    position: relative;
    padding: 0 1rem 0 1.4rem;
    font-size: 1.06rem;
    font-weight: ${({ primary }) => (primary ? 500 : 'bold')};
    font-family: 'Roboto', sans-serif;
    background: none;
    background-color: ${({ primary }) => (primary ? Color.primary : 'transparent')};
    border-radius: 0;
    border: none;
    outline: none;
    color: ${({ primary }) => (primary ? Color.white : Color.secondary)};

    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid transparent;
      border-radius: inherit;

      box-sizing: border-box;
    }

    &:hover {
      background-color: ${({ primary }) => (primary ? darken(0.1, Color.primary) : Color.hover)};

      &::after {
        border-color: ${Color.secondary};
      }
    }

    &:focus,
    &:active {
      background-color: ${({ primary }) => (primary ? darken(0.1, Color.primary) : Color.select)};

      &::after {
        border-color: ${Color.primary};
      }
    }

    > span.spacer {
      flex: 1;
    }

    > svg {
      margin-left: 0.2rem;
      color: ${({ primary }) => (primary ? Color.white : Color.primary)};

      &:first-child {
        margin-left: 0rem;
        margin-right: 0.3rem;
      }
    }

    .badge {
      margin-right: 0;
      margin-left: 0.5rem;
      font-weight: normal;
    }
  `,
};

export const OmnibarButton: FC<{
  icon?: JSX.Element;
  count?: number;
  iconRight?: JSX.Element;
  primary?: boolean;
  className?: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}> = ({ icon, count = 0, iconRight, primary = false, className = '', onClick, children }) => (
  <Styled.Button className={className} primary={primary} onClick={onClick}>
    {icon || null} {children} <span className="spacer" /> {count > 0 && <Badge>{count}</Badge>}{' '}
    {iconRight !== undefined ? iconRight : <MenuDownIcon />}
  </Styled.Button>
);
